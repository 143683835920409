export default {
  smsCode: '/basic/sms/sms-code', // 获取验证码
  pwdSmsCode: '/elebuys/basic/sms/sms/sms-code', // 获取验证码 - 忘记密码专用
  loginPageInfo: '/elebuys/right/login-page/query', // 获取登录页配置信息
  tabStatistical: '/elebuys/bpm/approve/tabStatistical', // 审批中心-tab数字角标接口
  industryList: '/elebuys/member/company/industry-list', // 行业列表
  remindGetListByPage: '/elebuys/blyc/remind/getListByPage', // 获取消息列表
  remindCountNum: '/elebuys/blyc/remind/countNum', // 统计消息数量
  remindRead: '/elebuys/blyc/remind/read', // 消息设为已读
  getGuessList: '/elebuys/support-center/article/guess-you-want-ask', // 猜你想问

  csPermission: '/ll/cs/permission', // 客服权限
  csShow: '/ll/cs/show', // 客服咨询权限

  getAuthModule: '/elebuys/company-rights-auth/employee-authorization', // 员工授权模块明细
};
